<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list">
    <v-select2 :v-model="current_category_list" :placeholder="setCurrentMarket()" :clearable="false" label="label" :searchable="false" :options="current_category_list" v-if="current_category_list" @input="setSelected"></v-select2>
    <MarketDynamic :parent_category_list="category_list" :is_home="is_home" v-if="category_list"></MarketDynamic>
</div>
</template>

<style lang="scss" scoped>
form i {
    margin-left: 5px;
}
</style>

<script>
// این ویو برای ماژول بازار بخش داینامیکش مورد استفاده قرار میگیرد
import MarketDynamic from "@/components/Market/Dynamic";
import vSelect2 from 'vue-select';

Vue.component('v-select2', vSelect2);

export default {
    name: "marketDynamicPage",
    props: ['child_category_list', 'static_market'],
    components: {
        MarketDynamic,
    },
    data: function () {
        return {
            category_list: null,
            current_category: null,
            defSelected: [],
            is_home: false,
            }
    },
    computed: {
        current_category_list() {
            if (this.$route.params.market_id == 'all') {
                return false;
            }
            if (this.$store.state.currentMarketLvl == 3) {
                return this.$store.state.marketLvl3;
            } else if (this.$store.state.currentMarketLvl == 4) {
                return this.$store.state.marketLvl3;
            }
            return false;
        },
    },
    mounted() {
        this.is_home = !window.location.href.includes('list');
        this.getCategoryList('all');
    },
    methods: {
        setCurrentMarket() {
            var placeholder = this.$store.state.marketLvl3.find(({ id }) => id === this.$route.params.market_id);
            if (placeholder) {
                return placeholder.label
            }
            return '';
        },
        setSelected(value) {
            this.$router.push({name: 'MarketList' , params: { market_id: value.id}});
        },
        // این متد با توجه به آرگومان ورود لیست بازار ها و یا دسته بندی ها را برمیگرداند
        getCategoryList(select_opt) {
            if (typeof this.static_market === 'undefined' && this.$route.params.market_id != 'all') {
                this.$helpers
                    .makeRequest("GET", "/market/category" + (select_opt == 'all' ? '' : '/' + select_opt))
                    .then((api_response) => {
                            this.category_list = api_response.data.response.detail.items;
                    });
            } else {
                if (typeof this.static_market === 'undefined') {
                    this.category_list = this.$store.state.marketState;
                    this.category_list = this.$store.state.marketLvl2;
                } else {
                    this.$store.commit('setMarketLvl2', this.static_market);
                    this.category_list = this.static_market;
                }
            }
        },
        // دکمه بازگشت
        back() {
            this.$router.go(-1);
        },
    }
};
</script>
